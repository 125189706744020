<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12">
        <h4>Search Engine Listing Preview</h4>
      </v-col>
    </v-row>
    <!--Preview-->
    <v-row>
      <v-col cols="12">
        <v-card
          color="pa-3"
          @click.stop="$set(seoDialog, 'display', true)"
          @mouseenter="$set(hover, 'display', true)"
          @mouseleave="$set(hover, 'display', false)"
        >
          <span class="seoTitle">{{ truncateTitle(returnTitle) }} </span>
          <br />
          <span class="seoPath">
            {{
              `http://www.website.com/${formatCategory(item.category)}${
                item.path
              }`
            }}
          </span>
          <br />
          <span class="seoDescription">{{
            truncateDescription(returnDescription)
          }}</span>
          <v-overlay
            absolute
            color="#ecebf2"
            opacity="0.7"
            v-show="hover.display"
          >
            <v-btn right text light>
              <v-icon small left>mdi-pencil</v-icon> Edit SEO Tags</v-btn
            >
          </v-overlay>
        </v-card>
        <SEOFieldsModal
          :dialog="seoDialog"
          :title="returnTitle"
          :description="returnDescription"
          :path="item.path"
          @saveSeoTags="$emit('saveSeoTags', $event)"
          @closeSeoModal="$set(seoDialog, 'display', false)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "SEOPreview",
  props: ["item"],
  components: {
    SEOFieldsModal: () => import("../Modals/SEOFieldsModal.vue"),
  },
  data() {
    return {
      hover: {},
      seoDialog: {},
    };
  },
  computed: {
    returnTitle() {
      return !this.item.seoTitle ? this.item.name : this.item.seoTitle;
    },
    returnDescription() {
      return !this.item.seoDescription
        ? this.item.description
          ? this.item.description
          : ""
        : this.item.seoDescription;
    },
  },
  methods: {
    truncateTitle(title) {
      return title && title.length <= 70 ? title : `${title.slice(0, 67)}...`;
    },
    truncateDescription(description) {
      return description && description.length <= 320
        ? description
        : `${description.slice(0, 317)}...`;
    },
    formatCategory(category) {
      return category.toLowerCase().split(" ").join("-");
    },
  },
};
</script>
<style scoped>
.seoTitle {
  color: #2e2baf;
  font-size: 1.1em;
}
.seoPath {
  color: #2b7b44;
}
.seoDescription {
  color: #646464;
}
.seoDescription,
.seoPath {
  font-size: 0.9em;
}
</style>